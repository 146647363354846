import { useState } from 'react';
import QuotesView from './QuotesView';
import { Upload, Info } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import './App.css';
import { parseLinesIntoClipping } from './parseLinesIntoClipping';
import { Clipping } from './Clipping';

function App() {

    const [clippings, setClippings] = useState<{ [key: string]: Clipping[] }>({});
    const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);

    const onDrop = (acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                const text = e.target?.result as string;
                const parsedClippings = parseMyClippingsTxtFile(text);
                setClippings(parsedClippings);
            };

            reader.readAsText(file);
        })
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { "text/plain": [".txt"] } })

    const resetClippings = () => {
        setClippings({});
    };

    const parseMyClippingsTxtFile = (text: string) => {
        const entries = text.split('==========');
        const rawClippings: { [key: string]: Clipping[] } = {};

        entries.forEach((entry) => {
            const clipping = parseLinesIntoClipping(entry);
            if (clipping) {
                if (!rawClippings[clipping.title]) {
                    rawClippings[clipping.title] = [];
                }
                rawClippings[clipping.title].push(clipping);
            }
        });

        // Collapse multiple clippings into one if one of them has a note
        for (const bookTitle in rawClippings) {
            const clippings = rawClippings[bookTitle];
            let i = 1;
            while (i < clippings.length) {
                if (clippings[i].kind === 'note') {
                    clippings[i - 1].note = clippings[i].content;
                    clippings.splice(i, 1);
                } else {
                    i++;
                }
            }
        }

        return rawClippings;
    }



    return (
        <div className="container mx-auto p-4 h-[calc(100vh-2rem)]">

            {Object.keys(clippings).length === 0 ? (
                <>
                    <div className="flex flex-col items-center justify-center mt-10">
                        <h1 className="text-3xl font-bold mb-4">MyClippings.txt <span className="text-sm font-normal">by <a href="https://twitter.com/feregri_no" target="_blank" rel="noopener noreferrer">@feregri_no</a></span></h1>
                        <button
                            onClick={() => setIsAboutModalOpen(true)}
                            className="flex items-center bg-gray-100 text-primary hover:text-primary-dark transition-colors mb-4"
                        >
                            <Info className="w-4 h-4 mr-1" />
                            What is the MyClippings.txt file?
                        </button>
                    </div>
                    <div
                        {...getRootProps()}
                        className="border-2 border-dashed border-gray-300 rounded-lg p-12 text-center cursor-pointer hover:border-primary transition-colors"
                    >
                        <input {...getInputProps()} />
                        <Upload className="mx-auto h-12 w-12 text-gray-400" />
                        <p className="mt-4 text-xl font-semibold">Drag & drop a MyClippings.txt file here, or click to select one</p>
                    </div>
                </>
            ) : (
                <QuotesView clippings={clippings} onReset={resetClippings} />
            )}

            {isAboutModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg p-6 max-w-md">
                        <h2 className="text-2xl font-bold mb-4">About MyClippings.txt</h2>
                        <p className="mb-4">
                            The <span className="font-semibold">MyClippings.txt</span> is a file generated by Kindle devices that contains all your highlights, notes, and bookmarks from your ebooks.
                        </p>
                        <p className="mb-4">
                            You can find this file by connecting your Kindle to a computer via USB and navigating to the Kindle's internal storage. The file is usually located in the "documents" folder.
                        </p>
                        <button
                            onClick={() => setIsAboutModalOpen(false)}
                            className="bg-gray-900 text-white px-4 py-2 rounded hover:bg-primary-dark transition-colors"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;
